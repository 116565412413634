import { shallowEqual } from "react-redux"

import {
  getClosestNextWorkingDay,
  getClosestPreviousWorkingDay,
  getMergedHolidays,
} from "~/helpers/holiday-helpers"
import { isLocalId } from "~/helpers/local-id"

import { assignmentBulkChangesRelay } from "~/mutations/Assignment"

import { showToast } from "~/containers/ToasterContainer"

import * as helpers from "./action_helpers"
import { itemsOverlap } from "./action_helpers"

type Assignment = {
  id: number
  person_id: number
  role_id: number
  project_id: number
  phase_id: number
  workstream_id: number
  start_date: string
  end_date: string
  minutes_per_day: number
  is_billable: boolean
  is_template: boolean
  non_working_day: boolean
  note?: string
  person?: {
    id: number
    is_placeholder: boolean
    time_offs: Array<TimeOff & { id: number }>
    assignments: Assignment[]
  }
}

type AssignmentWithId = {
  id: number
} & Assignment

type TimeOff = {
  start_date: string
  end_date: string
  leave_type: string
  id?: number
  person_id?: number | null
  minutes_per_day?: number
}

type TimeOffWithId = TimeOff & {
  id: number
}

type Person = {
  id: number
  first_name?: string
  last_name?: string
  is_placeholder: boolean
  assignments: readonly Assignment[]
  time_offs: ReadonlyArray<TimeOffWithId>
}

type Updates = {
  create: Assignment[]
  update: AssignmentWithId[]
  delete: AssignmentWithId[]
}

type DateRange = Readonly<{
  readonly id?: number
  readonly start_date: string
  readonly end_date: string
}>

export const bulkEditAssignment = async (updates: Updates) => {
  if (
    updates.delete.some((assignment) => isLocalId(assignment.id)) ||
    updates.update.some((assignment) => isLocalId(assignment.id))
  ) {
    // We dont know the real ids of these, so we can't actually make the
    // change. So dont allow changes. The calendar will auto revert when the
    // "newAssignment" returns from the server
    showToast({
      message:
        "Unable to update as the server is processing your previous request. Please try your action again.",
      type: "warning",
    })
    return
  }

  await assignmentBulkChangesRelay({ values: updates })
}

export const getOverlappingAssignments = (
  assignments: readonly Assignment[],
  a: Assignment,
) =>
  assignments
    .filter((a2) => {
      if (a.id === a2.id) {
        return false
      }
      if (a.project_id !== a2.project_id) {
        return false
      }
      if (a.role_id !== a2.role_id) {
        return false
      }

      if (a.workstream_id !== a2.workstream_id) {
        return false
      }

      return helpers.itemsOverlap(a, a2)
    })
    .sort(helpers.sortByStartDate)

export const calculateAssignmentsUpdates = (
  a: Assignment,
  person: Person,
  isConsistentTimeOffEnabled: boolean,
  ignoreItems: number[] = [],
) => {
  const assignmentChanges = {
    create: [],
    update: [],
    delete: [],
  }

  const isNew = !a.id || isLocalId(a.id)

  // If its a new assignment and new placeholder. Just create it right away.
  if (isNew && person.is_placeholder && !person.assignments) {
    assignmentChanges.create.push(a)
    return assignmentChanges
  }

  if (a.non_working_day) {
    const existingNonWorkingDays = getOverlappingAssignments(
      person.assignments.filter((a2) => a2.non_working_day), // only check other non_working_days
      a,
    )

    if (existingNonWorkingDays.length) {
      assignmentChanges.delete.push(...existingNonWorkingDays)
      assignmentChanges.create.push(a)
      return assignmentChanges
    }

    if (isNew) {
      assignmentChanges.create.push(a)
    } else {
      assignmentChanges.update.push(a)
    }
    return assignmentChanges
  }

  const filteredAssignments = person.assignments
    .filter((a2) => !ignoreItems.includes(a2.id)) // Ignore multi-select assignments so they dont try to merge with each other
    .filter((a2) => !a2.non_working_day) // Ignore non working days

  const overlappingProjectAssignments = getOverlappingAssignments(
    filteredAssignments,
    a,
  )
  const firstOverlap =
    overlappingProjectAssignments.length && overlappingProjectAssignments[0]

  // If new assignment (a) overlaps in the middle of a single assignment (firstOverlap)
  // -- i.e. (a) starts after firstOverlap.start_date & ends before firstOverlap.end_date --
  // and does not have same minutes_per_day or billable status or phase_ids
  const overlapsInMiddle =
    a.start_date > firstOverlap.start_date &&
    a.end_date < firstOverlap.end_date &&
    (a.minutes_per_day !== firstOverlap.minutes_per_day ||
      a.is_billable !== firstOverlap.is_billable ||
      a.phase_id !== firstOverlap.phase_id)

  if (overlapsInMiddle) {
    // update firstOverlap's start_date to a.end_date + 1
    const updatedLastAssignment = {
      ...overlappingProjectAssignments.pop(),
      start_date: helpers.addCalendarDays(a.end_date, 1),
      person_id: person.id,
    }
    assignmentChanges.update.push(updatedLastAssignment)

    // create new assignment that starts at firstOverlap.start_date and ends at a.start_date - 1
    const updatedFirstAssignment = {
      ...firstOverlap,
      person_id: person.id,
      end_date: helpers.subtractCalendarDays(a.start_date, 1),
    }

    assignmentChanges.create.push(updatedFirstAssignment)
  }

  // Assignment end_date is overlapping
  const lastOverlap =
    overlappingProjectAssignments[overlappingProjectAssignments.length - 1]
  if (
    lastOverlap &&
    !overlapsInMiddle &&
    (a.minutes_per_day !== lastOverlap.minutes_per_day ||
      a.is_billable !== lastOverlap.is_billable ||
      a.phase_id !== firstOverlap.phase_id) &&
    a.end_date !== helpers.getLatestDate(a.end_date, lastOverlap.end_date)
  ) {
    // New assignments partially overlaps an assignment with different minutes_per_day or phase_ids
    // Split it off as a new assignment
    const updatedLastAssignment = {
      ...overlappingProjectAssignments.pop(),
      start_date: helpers.addCalendarDays(a.end_date, 1),
      person_id: person.id,
    }
    assignmentChanges.update.push(updatedLastAssignment)
  }

  // Assignment start_date is overlapping
  if (
    firstOverlap &&
    !overlapsInMiddle &&
    (a.minutes_per_day !== firstOverlap.minutes_per_day ||
      a.is_billable !== firstOverlap.is_billable ||
      a.phase_id !== firstOverlap.phase_id) &&
    a.start_date !==
      helpers.getEarliestDate(a.start_date, firstOverlap.start_date)
  ) {
    // New assignments partially overlaps an assignment with different minutes_per_day or phase_ids
    // Split it off as a new assignment
    const updatedFirstAssignment = {
      ...overlappingProjectAssignments.shift(),
      end_date: helpers.subtractCalendarDays(a.start_date, 1),
      person_id: person.id,
    }
    assignmentChanges.update.push(updatedFirstAssignment)
  }

  const mergedAssignment = overlappingProjectAssignments.reduce(
    (a1, a2) => ({
      ...a1,
      start_date: helpers.getEarliestDate(a1.start_date, a2.start_date),
      end_date: helpers.getLatestDate(a1.end_date, a2.end_date),
    }),
    a,
  )

  const affectedTimeOffs =
    a.is_template || isConsistentTimeOffEnabled ? [] : person.time_offs

  const overlappingTimeOffs = affectedTimeOffs
    .filter((to) => !ignoreItems.includes(to.id)) // Remove multiselect timeoffs
    .filter((to) => !to.minutes_per_day) // Ignore partial time offs
    .filter(
      (to) =>
        to.leave_type !== "holiday" &&
        helpers.itemsOverlap(mergedAssignment, to),
    )
    .sort(helpers.sortByStartDate)
    .filter((to) => {
      // If assignment.start_date and to.start_date are the same. Then change
      // assignment start date, and remove the time off from the calculation
      // Unless the assignment its a single day assignment
      if (
        to.start_date === mergedAssignment.start_date &&
        to.end_date === mergedAssignment.start_date &&
        mergedAssignment.start_date !== mergedAssignment.end_date // skip for 1 day assignments
      ) {
        mergedAssignment.start_date = helpers.addCalendarDays(to.end_date, 1)
        return false
      } else {
        return true
      }
    })

  // If no time offs, we can save the new assignment, and delete any old assignments
  if (overlappingTimeOffs.length === 0) {
    isNew
      ? assignmentChanges.create.push(mergedAssignment)
      : assignmentChanges.update.push(mergedAssignment)
  } else if (
    // Timeoff complete covers the assignment
    helpers.isAfterOrSame(
      mergedAssignment.start_date,
      overlappingTimeOffs[0].start_date,
    ) &&
    helpers.isBeforeOrSame(
      mergedAssignment.end_date,
      overlappingTimeOffs[0].end_date,
    )
  ) {
    if (!isNew) {
      assignmentChanges.delete.push(mergedAssignment)
    }
  } else {
    // Update assignment with new start_end dates depending on when the first time off occurs
    // and handle an assignment getting moved to a start/end that overlaps timeoff.

    const updatedStartDate = helpers.isBeforeOrSame(
      overlappingTimeOffs[0].start_date,
      mergedAssignment.start_date,
    )
      ? helpers.addCalendarDays(overlappingTimeOffs[0].end_date, 1)
      : mergedAssignment.start_date

    let updatedEndDate = mergedAssignment.end_date

    if (
      helpers.isBefore(
        mergedAssignment.start_date,
        overlappingTimeOffs[0].start_date,
      )
    ) {
      updatedEndDate = helpers.subtractCalendarDays(
        overlappingTimeOffs[0].start_date,
        1,
      )
    } else if (
      overlappingTimeOffs.length > 1 &&
      helpers.isBefore(
        overlappingTimeOffs[1].start_date,
        mergedAssignment.end_date,
      )
    ) {
      updatedEndDate = helpers.subtractCalendarDays(
        overlappingTimeOffs[1].start_date,
        1,
      )
    }

    const updatedAssignment = {
      ...mergedAssignment,
      start_date: updatedStartDate,
      end_date: updatedEndDate,
    }

    // If there are time offs, we need to make new assignments
    const newAssignments = overlappingTimeOffs
      .filter((to) => {
        if (
          to.end_date ===
          helpers.getLatestDate(to.end_date, mergedAssignment.end_date)
        ) {
          return false
        } // remove timeoff if it ends after assignment ends
        if (
          to.start_date ===
          helpers.getEarliestDate(to.start_date, mergedAssignment.start_date)
        ) {
          return false
        } // remove timeoff if it ends after assignment ends
        return true
      })
      .map((to, i) => {
        const nextTimeoff = overlappingTimeOffs[i + 1]
        return {
          ...mergedAssignment,
          start_date: helpers.addCalendarDays(to.end_date, 1),
          end_date: nextTimeoff
            ? helpers.subtractCalendarDays(nextTimeoff.start_date, 1)
            : mergedAssignment.end_date,
        }
      })
      .filter((a2) => Number(a2.end_date) >= Number(a2.start_date))

    assignmentChanges.create = [...assignmentChanges.create, ...newAssignments]
    isNew
      ? assignmentChanges.create.push(updatedAssignment)
      : assignmentChanges.update.push(updatedAssignment)
  }

  assignmentChanges.delete = [
    ...assignmentChanges.delete,
    ...overlappingProjectAssignments,
  ]
  return assignmentChanges
}

export const bulkCalculateAssignmentUpdates = (
  assignments: Assignment[],
  ignoreAssignments: number[],
  isConsistentTimeOffEnabled: boolean,
) => {
  const assignmentChanges = {
    create: [],
    update: [],
    delete: [],
  }

  assignments.forEach((a) => {
    const person = { ...a.person } // We collect the person data for doing calculations
    delete a.person // Delete person so we don't send all the person data to the server.
    const updatedAssignments = calculateAssignmentsUpdates(
      a,
      person,
      isConsistentTimeOffEnabled,
      ignoreAssignments,
    )
    assignmentChanges.create.push(...updatedAssignments.create)
    assignmentChanges.update.push(...updatedAssignments.update)
    assignmentChanges.delete.push(...updatedAssignments.delete)
  })

  void bulkEditAssignment(assignmentChanges)
}

export const bulkAssignmentDelete = (assignments: AssignmentWithId[]) => {
  const assignmentChanges = {
    create: [],
    update: [],
    delete: assignments,
  }

  return void bulkEditAssignment(assignmentChanges)
}

export const calculateAssignmentUpdatesFromTimeOffs = (
  tOffs: ReadonlyArray<TimeOff>,
  assignments: ReadonlyArray<DateRange>,
  multiSelectItemIds: number[],
  holidays: ReadonlyArray<TimeOff>,
) => {
  const assignmentChanges = {
    create: [],
    update: [],
    delete: [],
  }
  // Remove multi-select assignments so they dont try to merge with each other
  const assignmentsExcludingMultiSelect = assignments.filter(
    (a) => multiSelectItemIds.includes(a.id) === false,
  )
  const sortedTimeoffs = [...tOffs].sort(helpers.sortByStartDate)

  const overlapping = assignmentsExcludingMultiSelect
    .filter((a) =>
      sortedTimeoffs.some(
        (to) => to.leave_type !== "holiday" && helpers.itemsOverlap(to, a),
      ),
    )
    .sort(helpers.sortByStartDate)

  sortedTimeoffs.forEach((tOff, i) => {
    const nextTimeOff = sortedTimeoffs[i + 1]
    overlapping.forEach((a) => {
      //******
      // the timeoff fully overlaps within an assignment
      // three assignments will need to be handled:
      // assignment before timeoff, assignment during timeoff, assignment after timeoff
      //******
      if (
        helpers.isBefore(a.start_date, tOff.start_date) &&
        helpers.isAfter(a.end_date, tOff.end_date)
      ) {
        const updatedEndDate = getClosestPreviousWorkingDay(
          holidays,
          helpers.subtractCalendarDays(tOff.start_date, 1),
        ).stringDate

        if (helpers.isBefore(updatedEndDate, a.start_date)) {
          // Delete instead of updating as it likely conflicts with holiday/timeoff/weekend
          assignmentChanges.delete.push(a)
        } else {
          // Update original assignment (assignment before time off)
          assignmentChanges.update.push({
            ...a,
            end_date: updatedEndDate,
          })
        }

        // Create middle assignment (assignment that becomes an exact conflict with time off)
        assignmentChanges.create.push({
          ...a,
          start_date: tOff.start_date,
          end_date: tOff.end_date,
        })

        const updatedStartDate = getClosestNextWorkingDay(
          holidays,
          helpers.addCalendarDays(tOff.end_date, 1),
        ).stringDate

        // Create end assignment (assignment after the time off)
        if (!helpers.isBefore(a.end_date, updatedStartDate)) {
          // If guards against holiday/timeoff/weekend
          assignmentChanges.create.push({
            ...a,
            start_date: updatedStartDate,
          })
        }

        return
      }

      //******
      // End of timeoff overlaps with start of assignment
      //******
      if (
        helpers.isBeforeOrSame(a.start_date, tOff.end_date) &&
        helpers.isAfter(a.end_date, tOff.end_date)
      ) {
        const newStartDate = getClosestNextWorkingDay(
          holidays,
          helpers.addCalendarDays(tOff.end_date, 1),
        ).stringDate

        assignmentChanges.create.push({
          ...a,
          end_date: tOff.end_date,
        })

        // If there's another time off ahead of this that will conflict,
        // split it again.
        if (
          nextTimeOff &&
          helpers.isAfter(a.end_date, nextTimeOff.start_date)
        ) {
          assignmentChanges.update.push({
            ...a,
            start_date: newStartDate,
            end_date: helpers.subtractCalendarDays(nextTimeOff.start_date, 1),
          })
          assignmentChanges.create.push({
            ...a,
            start_date: nextTimeOff.start_date,
          })
        } else {
          assignmentChanges.update.push({
            ...a,
            start_date: newStartDate,
          })
        }

        return
      }

      //******
      // Start of timeoff overlaps with end of assignment
      //******
      if (
        helpers.isAfterOrSame(a.end_date, tOff.start_date) &&
        helpers.isBefore(a.start_date, tOff.start_date)
      ) {
        const end_date = getClosestPreviousWorkingDay(
          holidays,
          helpers.subtractCalendarDays(tOff.start_date, 1),
        ).stringDate

        // Prevent duplicates due to lookahead. Once an assignment has been added to the updates, changes should be merged in. One update entry per ID.
        const existing = assignmentChanges.update.find(
          (existingA) => existingA.id === a.id,
        )
        if (existing) {
          existing.end_date = end_date
        } else {
          assignmentChanges.update.push({
            ...a,
            end_date,
          })
        }

        const toCreate = {
          ...a,
          start_date: tOff.start_date,
        }
        // Prevent duplicates, since the look ahead may have already added a create entry for this assignment
        if (
          !assignmentChanges.create.find((existingA) =>
            shallowEqual(existingA, toCreate),
          )
        ) {
          assignmentChanges.create.push(toCreate)
        }
        return
      }
    })
  })

  return assignmentChanges
}

export const calculateAssignmentUpdatesFromTimeOff = (
  tOff: TimeOff,
  assignments: ReadonlyArray<DateRange>,
  multiSelectItemIds: number[],
  holidays: ReadonlyArray<TimeOff>,
) => {
  return calculateAssignmentUpdatesFromTimeOffs(
    [tOff],
    assignments,
    multiSelectItemIds,
    holidays,
  )
}

export const updateAssignmentsFromTimeOff = (
  to: TimeOff,
  person: Person,
  multiSelectItemIds: number[],
) => {
  const filteredPersonAssignments = person.assignments.filter(
    (a) => !a.is_template,
  )

  const holidays = getMergedHolidays(person.time_offs)

  const assignments = calculateAssignmentUpdatesFromTimeOff(
    to,
    filteredPersonAssignments,
    multiSelectItemIds,
    holidays,
  )

  if (
    assignments.create.length ||
    assignments.update.length ||
    assignments.delete.length
  ) {
    void bulkEditAssignment(assignments)
  }
}

export const updateAndCreateAssignments = (
  a: Assignment,
  person: Person,
  isConsistentTimeOffEnabled: boolean,
): Promise<void> => {
  const assignments = calculateAssignmentsUpdates(
    a,
    person,
    isConsistentTimeOffEnabled,
    [],
  )

  return bulkEditAssignment(assignments)
}

export const transferMultipleAssignments = async (
  assignments: AssignmentWithId[],
  person: Person,
  isConsistentTimeOffEnabled: boolean,
) => {
  const changes = {
    create: [],
    update: [],
    delete: [],
  }

  assignments.forEach((a) => {
    changes.delete.push(a)
  })

  assignments.forEach((a) => {
    const assignmentChanges = calculateAssignmentsUpdates(
      { ...a, id: undefined },
      person,
      isConsistentTimeOffEnabled,
    )
    changes.create.push(...assignmentChanges.create)
    changes.update.push(...assignmentChanges.update)
    changes.delete.push(...assignmentChanges.delete)
  })

  await bulkEditAssignment(changes)
}

export const cloneMultipleAssignments = async (
  assignments: AssignmentWithId[],
  person: Person,
  isConsistentTimeOffEnabled: boolean,
) => {
  const changes = {
    create: [],
    update: [],
    delete: [],
  }

  assignments.forEach((a) => {
    const assignmentChanges = calculateAssignmentsUpdates(
      { ...a, id: undefined },
      person,
      isConsistentTimeOffEnabled,
    )
    changes.create.push(...assignmentChanges.create)
    changes.update.push(...assignmentChanges.update)
    changes.delete.push(...assignmentChanges.delete)
  })

  await bulkEditAssignment(changes)
}

export const createBulkEditForTimeOffConflicts = (person: Person) => {
  const assignmentChanges = {
    create: [],
    update: [],
    delete: [],
  }

  const { time_offs, assignments } = person

  const conflictingAssignments = assignments.filter(
    (a) =>
      !!time_offs.find(
        (to) => to.leave_type !== "holiday" && itemsOverlap(a, to),
      ),
  )

  conflictingAssignments.forEach((a) => {
    const updatedAssignments = calculateAssignmentsUpdates(a, person, false, [])

    assignmentChanges.create.push(...updatedAssignments.create)
    assignmentChanges.update.push(...updatedAssignments.update)
    assignmentChanges.delete.push(...updatedAssignments.delete)
  })

  return assignmentChanges
}

export const deleteTimeOffConflicts = (person: Person) => {
  const updates = createBulkEditForTimeOffConflicts(person)
  if (updates.create.length || updates.update.length || updates.delete.length) {
    return void bulkEditAssignment(updates)
  }
}

export const deleteTimeOffConflict = (timeOff, person) => {
  const clone = { ...person }
  clone.time_offs = clone.time_offs.filter((to) => to.id === timeOff.id)
  return deleteTimeOffConflicts(clone)
}
